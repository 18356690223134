/* eslint-disable camelcase */
import {I18nContext, T} from '@deity-io/falcon-i18n';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import productionConfiguration from '../../../../config/default.json';
import readConfig from '../../../utils/readClientConfiguration';
import calculateNcz30dPercentage from '../../../utils/calculateNcz30dPercentage';
import {useCartContext} from '../../../context/CartContext';
import {usePopup} from '../../../context/PopupContext';
import {removeFromCartEvent} from '../../../hooks/useAnalytics';
import TextButton from '../../UI/TextButton/TextButton';
import CartItemGiftWrap from '../CartItemGiftWrap/CartItemGiftWrap';
import useWishlist from '../../../hooks/useWishlist';
import Prices from '../../BaseCurrencyPrices/Prices';
import './CartItem.scss';
import ProductPrice from "../../Product/ProductPrice/ProductPrice";
import {currencySymbol} from "../../../utils/formatLocalePrice";

const country = readConfig(productionConfiguration, 'countryCode');
const isRs = country.includes('RS');

const CartItem = ({item, itemsRef, user, enableAlert, setEnableAlert, isLoyaltyCardActive}) => {
    const {
        id,
        available_gift_wrapping,
        product: {
            sku,
            days_to_delivery,
            is_on_order_only,
            name,
            weight,
            stock_status,
            gallery,
            canonical_url,
            gift_wrapping_available,
            advanced_product_inventory,
            price_last_30,
            prices: [{amount, old_amount, percentage, xumulus_rule_label, price_type}],
        },
    } = item;
    const {t} = useContext(I18nContext);
    const {showAddToCartPopupMessage} = usePopup();
    const {toggleInWishlist} = useWishlist(sku, name, id);
    const firstImage = gallery.find(image => image.position === 1);
    const anyImage = gallery.find(item => item);
    const originalQuantity = useRef(item.quantity);
    const {current: quantity} = originalQuantity;
    const [input, setInput] = useState(quantity);
    const {updateCartItem} = useCartContext();
    let enableQtyIncrements = '0';
    let qtyIncrements = 0;
    let unit = '';
    let unitWithSlash = '';
    if (advanced_product_inventory) {
        enableQtyIncrements = advanced_product_inventory.enable_qty_increments;
        qtyIncrements = Number(advanced_product_inventory.qty_increments);
        if (advanced_product_inventory.unit.charAt(0) === '/') {
            unit = advanced_product_inventory.unit.substring(1);
            unitWithSlash = advanced_product_inventory.unit;
        } else {
            unit = advanced_product_inventory.unit;
            unitWithSlash = '/' + advanced_product_inventory.unit;
        }
    }
    let [packageQuantity, setPackageQuantity] = useState(input / qtyIncrements);

    useEffect(() => {
        itemsRef.current[id] = input;
    }, [id, input]);
    if (quantity !== item.quantity) {
        originalQuantity.current = item.quantity;
        setInput(item.quantity);
    }
    const onChange = e => {
        const value = +e.target.value;
        if (!Number.isNaN(value) && value > 0) {
            if (enableQtyIncrements === '1') {
                setInput(value * qtyIncrements);
                setPackageQuantity(value);
            } else {
                setInput(value);
            }
        }
    };

    const rowTotal = {
        amount: parseFloat(item.prices.row_total_including_tax.value),
        currency_symbol: currencySymbol(item.prices.row_total_including_tax.currency),
    };

    const priceBox = () => {
        const basePrice = item.product.prices.find(x => x.price_type === 'simple_regular');
        const basePriceAmount = parseFloat(basePrice.amount);
        const currentPrice = {
            amount: item.prices.priceIncludingTax.value,
            currency_symbol: currencySymbol(item.prices.priceIncludingTax.currency),
        };
        const percentageDiscount = 100 - Math.round(currentPrice.amount * 100 / basePriceAmount, 0);
        const hasPromoPrice = percentageDiscount > 0;

        return (
            <>
                <div className={hasPromoPrice ? 'hasPromoPrice' : null}>
                    <ProductPrice price={basePrice} hideLabels={true}/>
                    {hasPromoPrice && <ProductPrice price={currentPrice} hideLabels={true}/>}
                </div>
                {
                    hasPromoPrice && <div className='cart-product__product-price__savings-percentage'>
                        <T id="product.discount"/>: {percentageDiscount}%
                    </div>
                }
            </>
        );
    };

    const onUpdateHandler = () => {
        updateCartItem(id, input).catch(err => {
            if (err.message.includes('product with SKU')) {
                showAddToCartPopupMessage(t('messagePopup.qtyError'), 'error', 4000);
            } else {
                showAddToCartPopupMessage(err.message, 'error', 4000);
            }
        });
    };

    function decrement() {
        if (enableQtyIncrements === '0') {
            if (input > 1) {
                setInput(input - 1);
            }
        } else if (packageQuantity > 1) {
            setInput(input - qtyIncrements);
            setPackageQuantity(packageQuantity - 1);
        }
    }


    function increment() {
        if (enableQtyIncrements === '0') {
            setInput(input + 1);
        } else {
            setInput(input + qtyIncrements);
            setPackageQuantity(packageQuantity + 1);
        }
    }


    return (
        <>
            <div className="cart-product">
                <div className="cart-product__product-info">
                    <div className="cart-product__product-image">
                        <Link to={`/${canonical_url}`}>
                            {firstImage && firstImage.url && <img src={firstImage.url} alt={firstImage.label}/>}
                            {!firstImage && anyImage && anyImage.url && <img src={anyImage.url} alt={anyImage.label}/>}
                        </Link>
                    </div>

                    <div className="cart-product__product-description">
                        <div className="cart-product__product-name">
                            <Link to={`/${canonical_url}`}>{name}</Link>
                        </div>

                        <div>
                            <T id="product.code"/>: {sku}
                        </div>
                        <div>
                            <T id="product.weight"/>: {weight} kg
                        </div>
                        {enableQtyIncrements === '1' && (
                            <div className="cart-product__unitText">
                                {packageQuantity.toFixed()} <T id="product.package"/>
                                (a) <T id="product.containing"/>{' '}
                                <b>
                                    {input.toFixed(3)} {unit}
                                </b>
                            </div>
                        )}
                        {stock_status === 'OUT_OF_STOCK' && (
                            <div className="cart-product__out-of-stock">
                                <T id="messagePopup.qtyError"/>
                            </div>
                        )}
                    </div>
                </div>

                <div className="cart-product__product-price">
                    <div className="cart-product__product-header">
                        <T id="cart.price"/>:
                    </div>

                    {priceBox()}
                </div>

                <div className="cart-product__quantity">
                    <div className="cart-product__product-header">
                        <T id="cart.quantity"/>:
                    </div>

                    <div className="cart-product__update-quantity">
                        <button type="button" className="cart-product__input-button" onClick={decrement}>
                            -
                        </button>
                        {enableQtyIncrements === '0' && (
                            <input type="number" className="cart-product__input" value={input} onChange={onChange}/>
                        )}
                        {enableQtyIncrements === '1' && (
                            <>
                                <input type="number" className="cart-product__input" value={packageQuantity}
                                       onChange={onChange}/>
                            </>
                        )}

                        <button type="button" className="cart-product__input-button" onClick={increment}>
                            +
                        </button>
                    </div>

                    <button type="button" className="cart-product__update" onClick={onUpdateHandler}>
                        <T id="cart.updateQuantity"/>
                    </button>

                    <div hidden={quantity === input} className="cart-product__update-message">
                        <T id="cart.updateNewQuantity"/>
                    </div>
                </div>

                <div className="cart-product__product-total">
                    <div className="cart-product__product-header">
                        <T id="cart.total"/>:
                    </div>

                    <div className="cart-product__product-total__price-total">
                        <ProductPrice price={rowTotal} hideLabels={true}/>
                    </div>
                </div>
            </div>

            {available_gift_wrapping && available_gift_wrapping.length > 0 && gift_wrapping_available === '1' && (
                <div className="cart-product__product-gift">
                    <CartItemGiftWrap giftWrapOptions={available_gift_wrapping} product={item}/>
                </div>
            )}

            <div className="cart-product__product-remove">
                {user && (
                    <p className="cart-product__product-add-wishlist" onClick={toggleInWishlist}>
                        <T id="cart.addWishlist"/>
                    </p>
                )}
                <TextButton
                    className="cart-product__remove-btn"
                    onClick={() => {
                        updateCartItem(id, 0);
                        removeFromCartEvent(item.product, quantity);
                    }}
                >
                    <T id="cart.removeItem"/>
                </TextButton>
            </div>

            {is_on_order_only !== 0 && days_to_delivery !== 0 && enableAlert && (
                <div className="modalBackground">
                    <div className="modalCartItem">
            <span className="modalText">
              <T id="cart.orderInTheStore"/>
            </span>
                        <br/>
                        <button type="button" onClick={() => setEnableAlert(false)} className="modalButton">
                            <T id="cart.ok"/>
                        </button>
                    </div>
                </div>
            )}

        </>
    );
};

export default CartItem;
