import {T} from '@deity-io/falcon-i18n';
import clsx from 'clsx';
import React from 'react';
import {useCartContext} from '../../../context/CartContext';
import './CartSummary.scss';
import ProductPrice from "../../Product/ProductPrice/ProductPrice";

const CartSummary = () => {
    const {cart} = useCartContext();
    if (!cart) {
        return null;
    }

    const {
        subtotal_including_tax: subtotal_including_tax,
        subtotal_original_including_tax: subtotal_original_including_tax,
        applied_taxes: appliedTaxes,
        grand_total: grandTotal,
        discounts,
    } = cart.prices;


    let savings = (parseFloat(subtotal_original_including_tax.value - subtotal_including_tax.value)).toFixed(2);
    const discountSum = discounts && discounts.reduce((sum, discount) => sum + discount.amount.value, 0);
    if (discountSum) {
        savings += discountSum;
    }

    if (savings < 1e-3) {
        savings = 0;
    }

    savings = (parseFloat(savings)).toFixed(2);

    const taxes = appliedTaxes.reduce((sum, appliedTax) => {
        return sum + appliedTax.amount.value;
    }, 0);

    const shippingAddress = cart && cart.shipping_addresses && cart.shipping_addresses[0];
    const currentShippingMethod = shippingAddress && shippingAddress.selected_shipping_method;

    const PriceRow = ({textId, text, amount, bigger, bold, color}) => {
        const className = clsx('price-row', {
            'price-row--bigger': bigger,
            'price-row--bold': bold,
            'price-row--color': color,
        });
        return (
            <div className={className}>
                <span className="price-row__label">{text || <T id={textId}/>}</span>
                <span className="price-row__amount">
                     <ProductPrice price={{amount:amount}} hideLabels={true}/>
                </span>
            </div>
        );
    };

    return (
        <>
            <div className="cart-totals">
                {savings > 0 &&
                    <PriceRow textId="cartSummary.originalPrice" amount={subtotal_original_including_tax.value}/>}

                {savings > 0 && <PriceRow textId="cartSummary.yourSavings" amount={savings} bigger color/>}

                {currentShippingMethod && (
                    <PriceRow text={currentShippingMethod.method_title} amount={currentShippingMethod.price_incl_tax.value}/>
                )}
                <PriceRow textId="cartSummary.taxes" amount={taxes}/>
                {discounts && <PriceRow textId="orders.discount" amount={-discountSum}/>}
                <PriceRow textId="cartSummary.totalFinal" amount={grandTotal.value} bigger bold/>
            </div>
        </>
    );
};

export default CartSummary;
