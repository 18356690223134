import React, { useEffect, useState } from 'react';
import { T } from '@deity-io/falcon-i18n';
import useResponsive from '../../../hooks/useResponsive';
import MobilePhone from '../../../assets/icons/mobile_phone.svg';
import ScrollToTopImage from '../../../assets/icons/footer-icon-scroll-to-top.png';
import './CartFooter.scss';
import readConfig from '../../../utils/readClientConfiguration';
import productionConfiguration from '../../../../config/default.json';

const phoneSupportNumber = readConfig(productionConfiguration, 'phoneSupportNumber');

const CartFooter = () => {
  const { isMobile } = useResponsive();
  const [isOnTop, setIsOnTop] = useState(true);

  useEffect(() => {
    window.onscroll = () => window.pageYOffset === 0 ? setIsOnTop(true) : setIsOnTop(false);
    return () => (window.onscroll = null);
  });

  const scrollToTopHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!isMobile) {
    return null;
  }

  return (
    <div className="cart-footer__info">
      {!isOnTop && (
        <div id="mobile-phone">
          <a href={`tel:${phoneSupportNumber}`}>
            <img loading="lazy" alt="mobile-phone" src={MobilePhone} width={50} height={50} />
          </a>
        </div>
      )}
      {!isOnTop && <div id="scroll-to-top">
        <a role="navigation" onClick={scrollToTopHandler}>
            <img
              loading="lazy"
              alt="scroll-to-top-button"
              id="scroll-to-top-button"
              src={ScrollToTopImage}
              width={50}
              height={50}
            />
        </a>
      </div>}
      <div className="cart-footer__info-help">
        <T id="cart.needHelp" />
        <a className="cart-footer__phone-link" href={`tel:${phoneSupportNumber}`}>
          {phoneSupportNumber}
        </a>
      </div>
      <div className="cart-footer__info-text">
        <div><T id="cart.supportWorkHours" /></div>
        <div><T id="cart.supportWorkHours2" /></div>
        <div><T id="cart.supportWorkHours3" /></div>
        <br />
        <T id="cart.supportMessage" />
      </div>
    </div>
  );
};

export default CartFooter;
