import React from 'react';
import {useCartContext} from "../../../context/CartContext";
import {useQuery} from "react-apollo";
import {FETCH_CONFIG} from "../../Product/ProductSidebarClub/ProductSidebarClub";
import {Loader} from "@deity-io/falcon-ecommerce-uikit";
import {T} from "@deity-io/falcon-i18n";

const CartLoyalityBlock = () => {
    const {cart, setMethod} = useCartContext();
    const {data: config, loading: loadingConfig} = useQuery(FETCH_CONFIG);
    if (!cart) {
        return null;
    }
    const {
        subtotal_including_tax: subtotal_including_tax,
        subtotal_original_including_tax: subtotal_original_including_tax,
        applied_taxes: appliedTaxes,
        grand_total: grandTotal,
        discounts,
    } = cart.prices;

    const rate = config && config.backendConfig.shop.loyaltyConversion;
    const loyaltyPoints = rate && (Math.floor(grandTotal.value / rate) || 0);
    const loyaltyPointsBanking = loyaltyPoints && loyaltyPoints * 2;


    return (
        <div className="loyalty-container">
            <div className="loyalty-container__header">
                <img
                    loading="lazy"
                    className="header"
                    src="/images/checkout/product-page-emm-club-header.png"
                    alt="loyalty-header"
                />
                <img loading="lazy" className="header-logo" src="/images/checkout/klub_kartica.png"
                     alt="loyalty-card"/>
            </div>

            <div className="loyalty-container__points">
                {loadingConfig ? (
                    <Loader height="xxl"/>
                ) : (
                    <>
                        <p className="loyalty-message">
                            <T id="cartSummary.loyaltyMessage"/>
                        </p>
                        <p className="loyalty-points">
                            <strong>{loyaltyPointsBanking}</strong> <T id="cartSummary.loyaltyPointsNoCard"/>
                        </p>
                        <p className="loyalty-points">
                            <strong>{loyaltyPoints}</strong> <T id="cartSummary.loyaltyPointsCard"/>
                        </p>
                    </>
                )}
            </div>
        </div>
    )
}

export default CartLoyalityBlock