import { useMemo } from 'react';
import { useCartContext } from '../context/CartContext';
import { UPDATE_CART } from '../queries/cart';
import useCartMutation from './useCartMutation';

export const useClearCart = () => {
  const { cart } = useCartContext();

  const cartItems = useMemo(() => {
    if (!cart || !cart.items) {
      return null;
    }

    return cart.items.map(item => ({
      cart_item_id: +item.id,
      quantity: 0,
    }));
  }, [cart]);

  return useCartMutation(UPDATE_CART, {
    variables: { cartItems },
  });
};
