import {I18nContext, T} from '@deity-io/falcon-i18n';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useCartContext} from '../../../context/CartContext';
import {usePopup} from '../../../context/PopupContext';
import {useUserContext} from '../../../context/UserContext';
import {useClearCart} from '../../../hooks/useClearCart';
import {cartViewEvent} from '../../../hooks/useAnalytics';
import Products from '../../Category/Products/Products';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';
import Button from '../../UI/Button/Button';
import TextButton from '../../UI/TextButton/TextButton';
import CartItem from '../CartItem/CartItem';
import CartSummary from '../CartSummary/CartSummary';
import loadable from "@loadable/component";

const QuarticonProductSlider = loadable(() => import('../../../renderComponents/ProductsSlider/QuarticonProductSlider'), {ssr: false});
import './CartContent.scss';
import TradedoublerContainerTag from '../../Tracking/Tradedoubler/TradedoublerContainerTag';
import CartLoyalityBlock from "../../Loyality/CartLoyalityBlock/CartLoyalityBlock";
// import CartShipping from "../CartShipping/CartShipping";

const CartContent = () => {
    const {cart, loading, updateCartItems} = useCartContext();
    const {user, isLoyaltyCardActive} = useUserContext();
    const {t} = useContext(I18nContext);
    const history = useHistory();

    const {showAddToCartPopupMessage} = usePopup();
    const [enableAlert, setEnableAlert] = useState(true);

    const [clearCart, {loading: clearLoading}] = useClearCart();

    const crosssellProducts = useMemo(
        () =>
            cart &&
            cart.items
                .filter(item => item && item.product.crosssell_products && item.product.crosssell_products.length)
                .flatMap(item => item.product.crosssell_products),
        [cart],
    );

    const itemsRef = useRef({});

    useEffect(() => {
        if (cart && cart.items) {
            cartViewEvent(cart);
            if (cart.items.some(item => item && item.product && item.product.stock_status === 'OUT_OF_STOCK')) {
                showAddToCartPopupMessage(t('cart.outOfStockItems'), 'error', 5000);
            }
        }
    }, [cart]);

    const onUpdateCart = () => {
        const items = Object.keys(itemsRef.current).map(key => ({
            cart_item_id: +key,
            quantity: itemsRef.current[key],
        }));

        updateCartItems(items).catch(err => {
            if (err.message.includes('product with SKU')) {
                const productRegex = /with SKU (\d+):/i;
                const sku = productRegex.exec(err.message)[1];
                const item = cart.items.find(({product}) => product.sku === sku);
                const message = t('messagePopup.orderQtyError').replace('[product]', item.product.name);
                showAddToCartPopupMessage(message, 'error', 4000);
            } else {
                showAddToCartPopupMessage(err.message, 'error', 4000);
            }
        });
    };

    if (!cart && loading) {
        return <ScreenLoader/>;
    }

    if (!cart || (cart && cart.items && cart.items.length === 0)) {
        return (
            <div className="empty-cart">
                <div className="empty-cart__content">
                    <img src="/images/checkout/icon-empty-cart.svg" alt="empty-cart-icon"/>
                    <div className="empty-cart__title">
                        <T id="emptyCart.isEmpty"/>
                    </div>
                    <div className="empty-cart__sub">
                        <T id="emptyCart.cartSad"/>
                    </div>
                </div>
                <div className="empty-cart__sub">
                    <T id="emptyCart.addProduct"/>
                </div>
                <Link to="/">
                    <Button trait="profile-hover" className="empty-cart__button">
                        <T id="emptyCart.toMain"/>
                    </Button>
                </Link>
                <QuarticonProductSlider type="empty_cart" cnt={3}/>
            </div>
        );
    }

    const clearCartHandler = async () => {
        try {
            await clearCart();
        } catch (e) {
            console.error(e.message);
        }
    };
    const cartProductIds=cart.items.map((item)=>{
      return item.product.sku
    })

    return (
        <div className="cart-content">
            <TradedoublerContainerTag cart={cart} page="basket"/>
            <TextButton className="cart-back" onClick={() => history.goBack()}>
                <span className="cart-back__icon">{'< '}</span>
                <T id="cart.goBack"/>
            </TextButton>

            {!user && (
                <p className="cart-content__guest">
                    <T id="cart.notLoggedIn"/>
                </p>
            )}

            <div className="cart-content__inner">
                <div className="cart-summary">
                    <div className="cart-summary__title">
                        <T id="cartSummary.title"/>
                    </div>

                    <CartLoyalityBlock/>
                    <hr/>
                    <CartSummary/>
                    {/*<hr/>*/}
                    {/*CartShipping Wyłaczone bo ieprawidłow wylicza koszty*/}
                    {/*<CartShipping/>*/}
                    <hr/>
                    <Link to="/checkout">
                        <button className="cart-summary__checkout-button" type="button">
                            <T id="cartSummary.checkout"/>
                        </button>
                    </Link>
                </div>
                <div className="cart-content__table">
                    <div className="cart-content__header">
                        <div className="cart-content__table-header">
                            <T id="cart.product"/>
                        </div>

                        <div className="cart-content__header-item cart-content__table-header">
                            <T id="cart.price"/>
                        </div>

                        <div className="cart-content__header-item cart-content__table-header">
                            <T id="cart.quantity"/>
                        </div>

                        <div className="cart-content__header-item cart-content__table-header">
                            <T id="cart.total"/>
                        </div>
                    </div>

                    <div className="cart-content__products">
                        {cart &&
                            cart.items &&
                            cart.items.map(
                                item =>
                                    item && (
                                        <CartItem
                                            itemsRef={itemsRef}
                                            key={item.id}
                                            item={item}
                                            user={user}
                                            enableAlert={enableAlert}
                                            setEnableAlert={setEnableAlert}
                                            isLoyaltyCardActive={isLoyaltyCardActive}
                                        />
                                    ),
                            )}
                    </div>

                    <div className="cart-content__buttons">
                        <Link to="/" className="cart-content__button cart-content__button_back">
                            <T id="cartSummary.backToHomepage"/>
                        </Link>

                        <button className="cart-content__button cart-content__button_update" onClick={onUpdateCart}>
                            <T id="cart.updateCart"/>
                        </button>

                        <button className="cart-content__button cart-content__button_remove" onClick={clearCartHandler}>
                            <T id="cart.emptyTheCart"/>
                        </button>
                    </div>

                    <div className="cart-content__notification">
                        <T id="cart.bookInfo"/>
                    </div>

                    <QuarticonProductSlider type="cart" isCart cnt={3} sku={cartProductIds[0]}/>

                    {crosssellProducts && crosssellProducts.length > 0 && (
                        <div className="cart-content__crosssell">
                            <div className="cart-content__crosssell__header">
                                <strong>
                                    <T id="cart.crosssellTitle"/>
                                </strong>
                            </div>
                            <div className="cart-content__crosssell__content">
                                <Products items={crosssellProducts}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {(loading || clearLoading) && <ScreenLoader/>}
        </div>
    );
};

export default React.memo(CartContent);
