import React from 'react';
import { T } from '@deity-io/falcon-i18n';
import { Link } from 'react-router-dom';
import useResponsive from '../../../hooks/useResponsive';
import './CartHeader.scss';
import readConfig from '../../../utils/readClientConfiguration';
import productionConfiguration from '../../../../config/default.json';
import Logo from '../../Header/Logo/Logo';
import CustomerService from "../../../screens/Checkout/CustomerService/CustomerService";

const countryCode = readConfig(productionConfiguration, 'countryCode');


const emmezetaCartImage = {
  'hr-HR': '/images/checkout/emmezeta-kosarica.png',
  'sr-RS': '/images/checkout/emmezeta-korpa.png',
  'sl-SI': '/images/checkout/emmezeta-blagajna-header.png',
};

const CartHeader = () => {
  const { isMobile } = useResponsive();

  return (
    <div className="cart-header">
      <div className="cart-header__logo">
        <Link to="/">
          <img src={emmezetaCartImage[countryCode]} alt="basket" className="cart-header__logo-img" />
        </Link>
      </div>

      {!isMobile && (
        <CustomerService/>
      )}
    </div>
  );
};

export default CartHeader;
