import { T } from '@deity-io/falcon-i18n';
import React, { useState } from 'react';
import './CartItemGiftWrap.scss';
import {useCartContext} from "../../../context/CartContext";

/*
const giftWrapOptions = [
  {
    id: 1,
    price: 0,
    currency: "kn",
    name: "Besplatno zamatanje poklona, rozi papir i roza traka",
    imageUrl: "https://cdn2.emmezeta.hr/media/wrapping/DSC_8924_o_4.jpg"
  },
  {
    id: 2,
    price: 0,
    currency: "kn",
    name: "Besplatno zamatanje poklona, rozi papir i siva traka",
    imageUrl: "https://cdn2.emmezeta.hr/media/wrapping/DSC_8920_o_3.jpg"
  },
  {
    id: 3,
    price: 0,
    currency: "kn",
    name: "Besplatno zamatanje poklona, natur papir i roza traka",
    imageUrl: "https://cdn2.emmezeta.hr/media/wrapping/DSC_8912_o_2.jpg"
  },
  {
    id: 4,
    price: 0,
    currency: "kn",
    name: "Besplatno zamatanje poklona, natur papir i siva traka",
    imageUrl: "https://cdn2.emmezeta.hr/media/wrapping/DSC_8911_o_1.jpg"
  },
];
 */

/*
    {
        "design": "Besplatno zamatanje poklona, rozi papir i roza traka",
        "image": {
            "label": "DSC_8924_o_4.jpg",
            "url": "https://cdn2.emmezeta.hr/media/wrapping/DSC_8924_o_4.jpg",
            "__typename": "GiftWrappingImage"
        },
        "price": {
            "currency": "HRK",
            "value": 0,
            "__typename": "Money"
        },
        "uid": "MQ==",
        "__typename": "GiftWrapping"
    },
 */

const optionDescription = (opt) => `${opt.design} ${opt.price.value.toFixed(2)} ${opt.price.currency}`;

const CartItemGiftWrap = ({giftWrapOptions, product}) => {
  const [previewWrap, setPreviewWrap] = useState(null);
  const [selectedWrap, setSelectedWrap] = useState(product.gift_wrapping ? product.gift_wrapping : null);
  const [showWrappingOptions, setShowWrappingOptions] = useState(false);
  const { updateCartItems } = useCartContext();

  const onWrapHandler = () => {
    // Same situation as the edit handler
    if(selectedWrap){
      setPreviewWrap(selectedWrap)
    }

    setShowWrappingOptions(!showWrappingOptions)
  }

  const onConfirmHandler = () => {
    setSelectedWrap(previewWrap);

    // Set the actual wrapping id on the item
    updateCartItems({
      cart_item_uid: product.uid,
      gift_wrapping_id: previewWrap.uid,
      quantity: product.quantity
    })
    setShowWrappingOptions(false);
  };


  const onEditHandler = () => {
    // When clicking edit, if we've pre-selected something - lets base our "edit" off that
    if(selectedWrap){
      setPreviewWrap(selectedWrap)
    }

    setShowWrappingOptions(true);
  }

  // Reset state & update
  const onRemoveHandler = () => {
    setPreviewWrap(null);
    setSelectedWrap(null);
    setShowWrappingOptions(false);
    // Set the actual wrapping id on the item
    updateCartItems({
      cart_item_uid: product.uid,
      gift_wrapping_id: null,
      quantity: product.quantity
    })
  }

  return (
    <div className="gift-wrapping">
      <button className="gift-wrapping-button" onClick={onWrapHandler}>
        <T id="giftWrap.wrapAsGift" />
      </button>

      <div hidden={!showWrappingOptions}>
        <div className="margin-tny-top">
          <T id="giftWrap.chooseOption" />
        </div>

        <ul className="gift-wrapping-list">
          {giftWrapOptions.map((opt) => (
            <li className="gift-wrapping-item" key={opt.uid} onClick={() => setPreviewWrap(opt)}>
              <img className="img-sml" src={opt.image.url} title={optionDescription(opt)} />
            </li>
          ))}
        </ul>

        {previewWrap && (
          <div className="gift-wrapping-preview">
            <img className="img-lrg" src={previewWrap.image.url} title={optionDescription(previewWrap)} />

            {optionDescription(previewWrap)}

            <button className="gift-wrapping-button margin-tny-left" onClick={onRemoveHandler}>
              <T id="giftWrap.remove" />
            </button>
          </div>
        )}

        <button className="gift-wrapping-button" onClick={onConfirmHandler}>
          <T id="giftWrap.confirm" />
        </button>

        <button className="gift-wrapping-button margin-tny-left" onClick={() => setShowWrappingOptions(false)}>
          <T id="giftWrap.cancel" />
        </button>
      </div>

      {selectedWrap && (
        <div className="gift-wrapping-selected" hidden={showWrappingOptions}>
          <div>
            <T id="giftWrap.selectedOption" />: {selectedWrap.design}
          </div>

          <div hidden={showWrappingOptions}>
            <img className="img-sml" src={selectedWrap.image.url} title={optionDescription(selectedWrap)} />
          </div>

          <div>
            {selectedWrap.price.value.toFixed(2)} {selectedWrap.price.currency}
          </div>

          <button className="gift-wrapping-button" onClick={onEditHandler}>
            <T id="giftWrap.edit" />
          </button>
        </div>
      )}
    </div>
  )
}

export default CartItemGiftWrap;
