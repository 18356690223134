import React from 'react';
import CartContent from '../../components/Cart/CartContent/CartContent';
import CartFooter from '../../components/Cart/CartFooter/CartFooter';
import CartHeader from '../../components/Cart/CartHeader/CartHeader';
import './Cart.scss';

const Cart = () => {
  return (
    <div className="cart">
      <CartHeader />

      <CartContent />

      <CartFooter />
    </div>
  );
};

export default React.memo(Cart);
