import React from 'react';
import {T} from "@deity-io/falcon-i18n";
import readConfig from "../../../utils/readClientConfiguration";
import productionConfiguration from "../../../../config/default.json";
import './CustomerService.scss';
const CustomerService = () => {
        const phoneSupportNumber = readConfig(productionConfiguration, 'phoneSupportNumber');
        return (
            <div className="cart-header__info">
                <div className="cart-header__info-text">
                    <div><T id="cart.supportWorkHours"/></div>
                    <div><T id="cart.supportWorkHours2"/></div>
                    <div><T id="cart.supportWorkHours3"/></div>
                    <br/>
                    <br/>
                    <T id="cart.supportMessage"/>
                </div>
                <a
                    href={`tel:${phoneSupportNumber}`}
                    name={<T id="product.callUsLinkName"/>}
                >
                <div className="cart-header__info-help">
                    <T id="cart.needHelp"/>
                    <div className="cart-header__phone">
                            {phoneSupportNumber}
                    </div>
                </div>
                </a>
            </div>
        );
    }
;

export default CustomerService;
